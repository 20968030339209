import Vue from 'vue';
import VueI18n from 'vue-i18n';

const messages = {
    en: {

    },
    es: {

    }
};

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', // idioma predeterminado
    fallbackLocale: 'en', // idioma de respaldo
    messages
});

export default i18n;