<template>
  <div>
    <header class="header">
        <div class="header-topbar">
		<div class="container"> 
							<div class="header-top-left"><strong>ANNOUNCEMENTS:</strong> <marquee scrolldelay="100" scrollamount="4" loop="infinite"><span>Join official TG community group for more updates.</span></marquee></div>
						<ul class="header-social-icons">
			
									<li class="telegram-icon"><a target="_blank" href="https://t.me/ugctoken" class="header-telegram"></a></li>
								
									<!-- <li class="discord-icon"><a target="_blank" href="https://discord.com/invite/mnQZ5Tuz"></a></li>
								
									<li class="twitter-icon"><a target="_blank" href="https://twitter.com/ibatofficial"></a></li>
								
									<li class="youtube-icon"><a target="_blank" href="https://www.youtube.com/channel/UCU9C0MLCexdjtF2h0_ErcOg"></a></li>
								
									<li class="facebook-icon"><a target="_blank" href="https://www.facebook.com/battleinfinityofficial/"></a></li>
								
									<li class="instagram-icon"><a target="_blank" href="https://www.instagram.com/battleinfinityofficial/?hl=en"></a></li> -->
					
			</ul>			
		</div>
	</div>
        
    <div id="wrapper-navbar" class="header_wrapper">
		<div class="container">
			<div class="header-wrapper-row row align-items-center">
			
				<div class="col-5 col-lg-2 col-md-2 no-gutters header-left pr-0">
					<router-link to="/">
						<img width="80" height="64" src="@/assets/logo.png" alt="UGCTOKEN">
					</router-link>				
				</div>
				<div class="header-toggle-btn" :class="{ 'header-toggle-active': isNavbarOpen }">
					<img width="25" height="17" class="header-toggle-icon" src="@/assets/images/icons/toggle-bar.svg" @click="toggleNavbar"><noscript><img width="25" height="17" class="header-toggle-icon" src="@/assets/images/icons/toggle-bar.svg"></noscript>
					<img width="19" height="19" class="header-close-icon" src="@/assets/images/icons/close-icon.svg" @click="toggleNavbar"><noscript><img width="19" height="19" class="header-close-icon" src="@/assets/images/icons/close-icon.svg"></noscript>
				</div>
				<div class="col-7 col-lg-10 col-md-10 header-right main-nav-col pl-0">
					<nav class="nav-primary" :class="{ 'menu-active': isNavbarOpen }" :style="isNavbarOpen ? 'display: block;' : ''">
						<div class="menu-main-menu-container">
							<ul id="menu-main-menu" class="nav">
								<li id="menu-item-1638" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1638"><a href="#" class="__mPS2id" >About</a>
									<ul class="sub-menu">
										<li id="menu-item-1025" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1025"><a href="javascript:void(0)" >Whitepaper</a></li>
									</ul>
								</li>

							<li id="menu-item-17" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-17"><router-link to="/tokenomics" class="__mPS2id" >Tokenomics</router-link></li>
							<!-- <li id="menu-item-18" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-18"><router-link to="/roadmap" class="__mPS2id" >Roadmap</router-link></li> -->
									<li id="menu-item-19" class="ps2id submenu-arrow menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-19"><a href="javascript:void(0)" class="__mPS2id" @click="toggleHowTo">How To</a>
										<ul class="sub-menu" :class="{ 'menu-sub-active': isHowToOpen }" :style="isHowToOpen ? 'display: block;' : ''">
											<li id="menu-item-1201" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1201"><router-link to="/how-to-buy-bnb-on-binance-step-by-step/" >How to Buy BNB on Binance (step-by-step)</router-link></li>
											<li id="menu-item-1262" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1262"><router-link to="/how-to-setup-trust-wallet/" >How to setup trust wallet</router-link></li>
											<li id="menu-item-1263" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1263"><router-link to="/how-to-setup-metask-wallet/" >How to setup metamask wallet</router-link></li>
											<li id="menu-item-1715" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1691 current_page_item menu-item-1715"><router-link to="/how-to-buy-on-pancakeswap/" aria-current="page" >How to buy on pancakeswap</router-link></li>
										</ul>
									</li>
							</ul>
				</div>					
			</nav>

					<div class="header-button">
						<a href="https://pancakeswap.finance/swap?outputCurrency=0x295F2590ecfB3B2A68fBe74bc2E6b08AcCf363DD" class="custom-btn custom-btn-bg" target="_blank">Buy UGC</a>
					</div>

				</div>
				
			</div> 
		</div>
	</div>

    </header>
  </div>
</template>

<script>
export default {
  name: 'UgctokenHeader',

  data() {
    return {
		isNavbarOpen: false,
		isHowToOpen: false,
    };
  },

  mounted() {
    
  },

  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
	toggleHowTo() {
      this.isHowToOpen = !this.isHowToOpen;
    },
  },
};
</script>