import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/how-to-setup-trust-wallet',
    name: 'how-to-setup-trust-wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "trust" */ '../views/Trust.vue')
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "roadmap" */ '../views/Roadmap.vue')
  },
  {
    path: '/tokenomics',
    name: 'tokenomics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tokenomics" */ '../views/Tokenomics.vue')
  },
  {
    path: '/how-to-buy-bnb-on-binance-step-by-step',
    name: 'how-to-buy-bnb-on-binance-step-by-step',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bnb" */ '../views/BuyBnB.vue')
  },
  {
    path: '/how-to-buy-on-pancakeswap',
    name: 'how-to-buy-on-pancakeswap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pancake" */ '../views/Pancakeswap.vue')
  },
  {
    path: '/how-to-setup-metask-wallet',
    name: 'how-to-setup-metask-wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "metamask" */ '../views/Metamask.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {

  //const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  //if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  }

  next();
});

export default router;
