<template>
  <div>
    <footer class="footer-info">
  <div class="container">
    <div class="row">
					<div class="col-12 footer-navcl">
                        <div class="widget widget_nav_menu">
                            <div class="widget-content">
                                <div class="menu-footer-menu-container">
                                    <ul id="menu-footer-menu" class="menu">
  <li id="menu-item-1641" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1641"><router-link to="/tokenomics" data-ps2id-api="true" class="__mPS2id" data-layout="Footer">Tokenomics</router-link></li>
  <!-- <li id="menu-item-1642" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1642"><router-link to="/roadmap" data-ps2id-api="true" class="__mPS2id" data-layout="Footer">Roadmap</router-link></li> -->
  <li id="menu-item-1643" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1643"><a href="javascript:void(0)" data-ps2id-api="true" data-layout="Footer">WHITEPAPER</a></li>
  <li id="menu-item-1646" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1646"><a href="javascript:void(0)" data-ps2id-api="true" class="__mPS2id" data-layout="Footer">Contact</a></li>
</ul>
</div>
</div>
</div>
</div>
			</div>
	<div class="row">
					<div class="col-12 footer-one footer2">
                        <div class="widget widget_block widget_text">
                            <div class="widget-content">
<p>Disclaimer: Your investment may go down as well as up in value.</p>
</div></div></div>
				
					<div class="col-12 footer-one footer3">
            <div class="widget widget_block widget_text">
              <div class="widget-content">
<p>Copyright © {{currentYear()}} - All rights reserved by UGCTOKEN</p>
</div>
</div>
</div>
				
				
				
				
	  </div>
  </div>

</footer>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'UgctokenFooter',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    currentYear() {
        return moment().year();
    },
  },
};
</script>